import { memo, useState } from "react";
import PropTypes from "prop-types";
import "./DepartureDateSidePanelContent.scss";
import Button from "app/pages/.shared/form/Button";
import CalendarList from "app/pages/.shared/CalendarList/CalendarList";
import { FormattedDate, FormattedMessage } from "react-intl";
import IconClose from "app/pages/.shared/IconClose";
import { getMonthsYearsInDateInterval } from "app/utils/utils";
import { compact } from "lodash";
import className from "classnames";
import IconCloseFull from "app/pages/.shared/IconCloseFull";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const DepartureDateSidePanelContent = ({
	initialDate,
	onDateSelect,
	onDateConfirm,
	onClose,
	departureDateMin,
	departureDateMax,
	checkDateAvailability,
	isCalendarDisabled,
	calendarDisabledView,
}) => {
	const [selectedDate, setSelectedDate] = useState(compact(initialDate));

	const handleDateSelect = date => {
		let newDate;
		// if selectedDate(departDate, endDate) exists, reset selectedDate
		if (selectedDate.length >= 2) {
			newDate = [].concat(date);
		} else {
			newDate = [...selectedDate, date];
		}
		setSelectedDate(newDate);
		onDateSelect(newDate);
	};

	const handleDateConfirmation = () => {
		onDateConfirm(selectedDate);
	};

	let months = [];
	if (departureDateMin && departureDateMax) {
		months = getMonthsYearsInDateInterval(departureDateMin, departureDateMax);
	}

	const handleCleanDepartureDate = () => {
		setSelectedDate([]);
		onDateSelect([]);
	};
	const handleCleanArrivalDate = () => {
		const newDate = [].concat(selectedDate[0]);
		setSelectedDate(newDate);
		onDateSelect(newDate);
	};

	return (
		<div className="departure-date-side-panel-content">
			<header className="departure-date-side-panel-content__header">
				<div className="departure-date-side-panel-content__header-title">
					<Typography variant={TYPOGRAPHY_VARIANTS.XL} isBold>
						<FormattedMessage id="sdp.search.departure.date.input.label" />
					</Typography>
					<div
						onClick={onClose}
						className="departure-date-side-panel-content__header-icon"
					>
						<IconClose width={24} height={24} />
					</div>
				</div>
				<div className="departure-date-side-panel-content__dates-selected">
					<div
						className={className({
							"departure-date-side-panel-content__date-selected": true,
							"departure-date-side-panel-content__date-selected--has-value":
								selectedDate[0],
						})}
					>
						<div>
							<p className="departure-date-side-panel-content__date-selected-label">
								<FormattedMessage id="sdp.search.departure.date.side.panel.title" />
							</p>
							{selectedDate[0] && (
								<p className="departure-date-side-panel-content__date-selected-value">
									<FormattedDate
										value={new Date(selectedDate[0])}
										day="2-digit"
										month="2-digit"
										year="numeric"
									/>
								</p>
							)}
						</div>
						{selectedDate[0] && (
							<div
								className="departure-date-side-panel-content__date-selected-clear-icon"
								onClick={handleCleanDepartureDate}
							>
								<IconCloseFull />
							</div>
						)}
					</div>
					<div
						className={className({
							"departure-date-side-panel-content__date-selected": true,
							"departure-date-side-panel-content__date-selected--has-value":
								selectedDate[1],
							"departure-date-side-panel-content__date-selected--disabled": !selectedDate[0],
						})}
					>
						<div>
							<p className="departure-date-side-panel-content__date-selected-label">
								<FormattedMessage id="sdp.search.destination.date.side.panel.title" />
							</p>
							{selectedDate[1] && (
								<p className="departure-date-side-panel-content__date-selected-value">
									<FormattedDate
										value={new Date(selectedDate[1])}
										day="2-digit"
										month="2-digit"
										year="numeric"
									/>
								</p>
							)}
						</div>
						{selectedDate[1] && (
							<div
								className="departure-date-side-panel-content__date-selected-clear-icon"
								onClick={handleCleanArrivalDate}
							>
								<IconCloseFull />
							</div>
						)}
					</div>
				</div>
			</header>
			{isCalendarDisabled ? (
				calendarDisabledView
			) : (
				<div className="departure-date-side-panel-content__body">
					<CalendarList
						heightOffset={250} // header + footer with CTA + selection overview
						selectedDate={selectedDate}
						months={months}
						onDateSelect={handleDateSelect}
						departureDateMin={departureDateMin}
						departureDateMax={departureDateMax}
						checkDateAvailability={checkDateAvailability}
					/>
				</div>
			)}
			<footer className="departure-date-side-panel-content__footer">
				{!isCalendarDisabled && (
					<Button
						disabled={selectedDate.length !== 2}
						variant="primary"
						onClick={handleDateConfirmation}
					>
						<FormattedMessage id="sdp.search.departure.date.side.panel.cta" />
					</Button>
				)}
			</footer>
		</div>
	);
};

DepartureDateSidePanelContent.propTypes = {
	onDateSelect: PropTypes.func,
	onDateConfirm: PropTypes.func,
	initialDate: PropTypes.array,
	onClose: PropTypes.func,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	checkDateAvailability: PropTypes.func,
	isCalendarDisabled: PropTypes.bool,
	calendarDisabledView: PropTypes.element,
};

export default memo(DepartureDateSidePanelContent);
